<template>
  <div class="constrain part">
    <div
      ref="modal"
      class="image-modal"
      :class="{visible: isModalVisible}"
      @click="closeModal"
    >
      <img :src="payload.bild.large" :alt="payload.bild.alt">
      <div class="modal-close-btn">
        <Xsolid />
      </div>
    </div>
    <div class="row-12">
      <div class="md:offset-2 md:col-8">
        <div class="text text-navy" v-html="payload.text" />
      </div>
    </div>
    <div class="row-12">
      <div class="md:col-8 md:offset-2">
        <img
          ref="img"
          class="image mt-8"
          :src="payload.bild.large"
          :alt="payload.bild.alt"
          @click="enlargeImage"
        >
      </div>
    </div>
  </div>
</template>

<script>

import Xsolid from '../../assets/images/x-solid.svg';

export default {
  components: {
    Xsolid,
  },
  props: {
    payload: Object,
  },
  data() {
    return {
      isModalVisible: false,
    };
  },
  methods: {
    enlargeImage() {
      this.isModalVisible = true;
      document.querySelector('body').classList.add('modal-active');
    },
    closeModal() {
      this.isModalVisible = false;
      document.querySelector('body').classList.remove('modal-active');
    },
  },
};
</script>

<style lang="scss" scoped>
.text {
 &:deep(p) {
   line-height: 1.75;
 }
 &:deep(h4) {
   margin-bottom: 1rem;
 }
}

.image {
  width: 100;
  object-fit: cover;
  object-position: center;
  cursor: zoom-in;
}

.image-modal {
  display: flex;
  pointer-events: none;
  z-index: 115;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: rgba(black, 0.5);
  opacity: 0;
  transition: opacity 0.25s ease-out;
  img {
    width: 75%;
  }
  &.visible {
    pointer-events: auto;
    opacity: 1;
  }
}

.modal-close-btn {
  z-index: 120;
  position: absolute;
  top: 0;
  right: 0;
  width: px(40);
  height: px(40);
  background-color: var(--color-navy);
  padding: 7px;
  cursor: pointer;
  svg {
    height: 100%;
    width: 100%;
    color: var(--color-white);
  }
}
</style>
